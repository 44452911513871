// Add to src/style/_nav.scss
.ai-chat-toggle {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}

// Add these new classes
.main-content-wrapper {
  transition: margin-right 0.3s ease;
  width: 100%;

  &.chat-open {
    margin-right: 400px; // Same as chat panel width
    width: calc(100% - 400px);
  }
}

.ai-chat-container {
  position: fixed;
  right: -400px;
  top: 0;
  width: 400px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1999;

  &.show {
    right: 0;
  }

  // Ensure the AIChat component fills the container
  > div {
    height: 100%;
    max-width: 100% !important;
    margin: 0 !important;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  pointer-events: none; // Allow clicking through the container

  &.chat-open {
    right: 400px; // Match the AI chat width
  }

  // Ensure modals themselves can receive clicks
  .modal {
    pointer-events: auto;
  }
}

.modal.chat-open {
  .modal-dialog {
    max-width: calc(100% - 400px - 2rem);
    margin-right: calc(400px + 1rem);
    transition: max-width 0.3s ease, margin-right 0.3s ease;
  }
}

.modal.chat-open {
  .modal-content {
    max-width: 100%;
  }
}

// Chat component specific styles
.ai-chat {
  border-radius: 0;
  border-left: 1px solid #dee2e6;

  &-header {
    padding: 0.75rem 1rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
  }

  &-messages {
    scrollbar-width: thin;
    scrollbar-color: #adb5bd transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #adb5bd;
      border-radius: 3px;
    }
  }

  &-input {
    background-color: #f8f9fa;

    .form-control {
      border-radius: 20px;
      padding: 0.5rem 1rem;
    }
  }

  // Message bubbles
  .bg-primary {
    border-radius: 1.25rem 1.25rem 0 1.25rem;

    p {
      margin-bottom: 0.5rem;
      line-height: 1.4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .bg-light {
    border-radius: 1.25rem 1.25rem 1.25rem 0;

    p {
      margin-bottom: 0.5rem;
      line-height: 1.4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // Quick questions
  &-quick-questions {
    background-color: #f8f9fa;

    .btn-outline-secondary {
      border-radius: 20px;
      white-space: nowrap;
    }
  }
}

// Message Actions
.ai-chat-messages {
  .btn-link {
    padding: 0.25rem;
    opacity: 0.7;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
      text-decoration: none;
    }

    .svg-inline--fa {
      width: 14px;
      height: 14px;
      vertical-align: middle;
    }
  }

  p {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  a {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 0.2em;

    &:hover {
      text-decoration: none;
    }
  }

  code {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-size: 0.9em;
  }

  pre {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;

    code {
      background: none;
      padding: 0;
      font-size: 0.85em;
    }
  }
}

.ai-chat-header {
  padding: 0.75rem 1rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;

  // Add these new rules
  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px; // Adjust based on your needs
  }

  small {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .btn {
    flex-shrink: 0;
    margin-left: 1rem;
  }
}

.quick-question-btn {
  font-size: 0.765rem;
  line-height: 0.8;
  height: 23px;
}
